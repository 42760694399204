import { defineStore } from 'pinia';
import { type Ref, ref } from 'vue';

export const useModalStore = defineStore('modalStore', () => {
    const imageTitle: Ref<string|null> = ref(null);
    const imagePaths: Ref<string[]> = ref([]);
    const showModal: Ref<boolean> = ref(false);

    // Actions
    function setModalData(
        newImageTitle: string | null,
        newImagePaths: string[],
        setShowModal: boolean,
    ) {
        imageTitle.value = newImageTitle;
        imagePaths.value = newImagePaths;
        showModal.value = setShowModal;
    }

    function resetModal() {
        imageTitle.value = null;
        imagePaths.value = [];
        showModal.value = false;
    }

    return {
        imageTitle,
        imagePaths,
        showModal,
        setModalData,
        resetModal,
    };
});
